import React, { useEffect } from "react";
import styles from "./index.module.scss";
import cn from "classnames";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { LinkButton } from "../../../../elements/linkbutton";

export const Banner = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <section className={styles.section}>
      <div className={styles.background_box}>
        <img className={cn(styles.background_img)} src={require("../../../../../assets/images/homepage/grampus_banner_back.png")} alt="background" />
        <div className={cn("pc", styles.gradient)} />
      </div>
      <div className={cn("container", styles.container)}>
        <div className={styles.title_box}>
          Explore the Infinite,
          <br />
          Play Beyond Limits
        </div>
        <div className={styles.contents_box}>
          Experience Web3 Gaming that expands infinitely
          <br />
          with blockchain technology.
          <br />
          Connect with GRAMers in the community to play games
          <br />
          and share your ideas for the ecosystem.
        </div>
        {/* <div className={styles.buttons_box}>
          <LinkButton className={styles.play_button} link="https://iskra.world/arcade/JUICY_ADVENTURE">
            ISKRA Arcade
          </LinkButton>
        </div> */}
      </div>
    </section>
  );
};
