import BaseAPIClient from "..";
import { getRandomNonce } from "../../../utility/helpers";

class GrampusClient extends BaseAPIClient {
  constructor() {
    super(process.env.REACT_APP_SERVER_URL_PREFIX, {
      Accept: "text/plain",
      "Content-Type": "application/json",
    });
  }

  getContract = (category) => {
    return this.get("/contract?category=" + category);
  };

  getContractList = () => {
    return this.get("/contract/list");
  };

  getStakingInfo = () => {
    return this.get("/staking");
  };

  getExchangeInfo = () => {
    return this.get("/exchange");
  };

  // deprecated
  getSeasonList = () => {
    return this.get("/season");
  };

  // deprecated
  getFriendsList = (season) => {
    return this.get("/friends?season=" + season);
  };

  addExchangeItem = (accessToken, classification, hash, contractAddress, chainId) => {
    const data = {
      accessToken: accessToken,
      classification: classification,
      hash: hash,
      contractAddress: contractAddress,
      chainId: chainId,
      currentTimeUnix: Math.floor(Date.now() / 1000),
    };
    return this.post("/exchange", data);
  };

  addStakingItem = (accessToken, classification, hash, contractAddress, chainId) => {
    const data = {
      accessToken: accessToken,
      classification: classification,
      hash: hash,
      contractAddress: contractAddress,
      chainId: chainId,
      currentTimeUnix: Math.floor(Date.now() / 1000),
    };
    return this.post("/staking", data);
  };

  // deprecated
  getCertification = (userId, season) => {
    return this.get("/certification?userId=" + userId + "&season=" + season);
  };

  // deprecated
  addCertification = (accessToken, season, address, wallet) => {
    const data = {
      accessToken: accessToken,
      season: season,
      walletAddress: address,
      wallet: wallet,
    };
    return this.post("/certification", data);
  };

  getNonce = () => {
    return this.get("/ocs/nonce");
  };

  verifySignature = (message, signature, wallet) => {
    return this.post("/ocs/verify", { message, signature, wallet });
  };

  getMintingData = (address, selectOrder) => {
    const randomNonce = getRandomNonce(16);

    return this.post("/ocs/minting", {
      address,
      nonce: randomNonce,
      order: selectOrder,
    });
  };

  isOcsBlockEnabled = () => {
    return this.get("/ocs/enabled");
  };

  getBalances = (address, token) => {
    return this.get("/dashboard/balances?address=" + address + "&accessToken=" + token);
  };

  getTokenInfo = () => {
    return this.get("/token/info");
  };
}

export default new GrampusClient();
