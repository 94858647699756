import React, { useEffect, useState } from "react";
import cn from "classnames";
import copy from "copy-to-clipboard";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.scss";
import { Link } from "react-router-dom";
import Icon from "../../Icon";
import { getShortenedAddress, displayBalanceFloat } from "../../../utility/helpers";
import Popup from "reactjs-popup";
import { useIskraV2 } from "../../../module/hook/v2/iskra";
import { LinkButton } from "../linkbutton";
import { useNavigate } from "react-router-dom";
import { Loading } from "../loading/Loading";

export const User = ({ className, collapsePanel, isMobileLayout }) => {
  const { login, logout, requestAccountsFromIskra, userData, walletAddress, balanceLoading, getBalanceByName } = useIskraV2();

  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const [tokenInfoMap, setTokenInfoMap] = useState(new Map());

  useEffect(() => {
    if (balanceLoading) {
      setTokenInfoMap(new Map());
      return;
    }

    const newTokenInfoMap = new Map();
    newTokenInfoMap.set("kaia/candy", getBalanceByName("kaia", "candy"));
    newTokenInfoMap.set("base/gram", getBalanceByName("base", "gram"));
    newTokenInfoMap.set("base/staking", getBalanceByName("base", "staking"));
    newTokenInfoMap.set("base/jelly", getBalanceByName("base", "jelly"));

    setTokenInfoMap(newTokenInfoMap);
  }, [balanceLoading, getBalanceByName]);

  useEffect(() => {
    if (userData && !walletAddress) {
      requestAccountsFromIskra();
    }
  }, [userData, walletAddress, requestAccountsFromIskra]);

  const onLoginButtonClicked = () => {
    login().catch(showError);
  };

  const onLogoutButtonClicked = () => {
    logout();
    setVisible(false);
  };

  const showError = (error) => {
    alert(error);
  };

  const onProfileButtonClicked = () => {
    if (isMobileLayout) {
      navigate("/dashboard");
      collapsePanel();
    } else {
      setVisible(!visible);
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className)}>
        {!userData && (
          <div className={styles.login_box}>
            <button className={cn(styles.login_button)} onClick={() => onLoginButtonClicked()}>
              <span>Sign in</span>
            </button>
          </div>
        )}
        {userData && (
          <div className={styles.login_box}>
            <button className={cn(styles.login_button)} onClick={onProfileButtonClicked}>
              <span>My Profile</span>
            </button>
          </div>
        )}
        {userData && visible && (
          <div className={styles.outer}>
            <div className={styles.body}>
              <div className={styles.name}>{userData.email}</div>
              {userData && (
                <div className={styles.code}>
                  <div className={styles.number}>{getShortenedAddress(walletAddress)}</div>
                  <Popup
                    onOpen={() => copy(walletAddress)}
                    trigger={
                      <button className={styles.copy}>
                        <Icon name="copy" size="16" />
                      </button>
                    }
                    position="top center"
                    closeOnDocumentClick
                  >
                    Copied!
                  </Popup>
                </div>
              )}
              <div className={styles.wrap}>
                {userData && (
                  <div className={styles.token_container}>
                    <div className={styles.chain_container}>
                      <div className={styles.chain_box}>
                        <img src={require("../../../assets/images/homepage/profile/profile_kaia_icon.png")} alt="kaia" />
                        KAIA main net
                      </div>
                      <div className={styles.line}>
                        <div className={styles.info}>CANDY Balance</div>
                        <div className={styles.details}>
                          <div className={styles.preview}>
                            <img src={require("../../../assets/images/homepage/profile/profile_candy_kaia_icon.png")} alt="candy" />
                          </div>
                          <div className={styles.price_container}>
                            <div className={styles.name}>CANDY</div>
                            {balanceLoading ? <Loading className={styles.loading} /> : <div className={styles.price}>{displayBalanceFloat(tokenInfoMap.get("kaia/candy") || 0)}</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.chain_container}>
                      <div className={styles.chain_box}>
                        <img src={require("../../../assets/images/homepage/profile/profile_base_icon.png")} alt="kaia" />
                        BASE main net
                      </div>
                      <div className={styles.line}>
                        <div className={styles.info}>GRAM Balance</div>
                        <div className={styles.details}>
                          <div className={styles.preview}>
                            <img src={require("../../../assets/images/homepage/profile/profile_gram_base_icon.png")} alt="gram" />
                          </div>
                          <div className={styles.price_container}>
                            <div className={styles.name}>GRAM</div>
                            {balanceLoading ? <Loading className={styles.loading} /> : <div className={styles.price}>{displayBalanceFloat(tokenInfoMap.get("base/gram") || 0)}</div>}
                          </div>
                        </div>
                      </div>
                      <div className={styles.line}>
                        <div className={styles.info}>sGRAM Balance</div>
                        <div className={styles.details}>
                          <div className={styles.preview}>
                            <img src={require("../../../assets/images/homepage/profile/profile_sgram_base_icon.png")} alt="sgram" />
                          </div>
                          <div className={styles.price_container}>
                            <div className={styles.name}>sGRAM</div>
                            {balanceLoading ? <Loading className={styles.loading} /> : <div className={styles.price}>{displayBalanceFloat(tokenInfoMap.get("base/staking") || 0)}</div>}
                          </div>
                        </div>
                      </div>
                      <div className={styles.line}>
                        <div className={styles.info}>JELLY Balance</div>
                        <div className={styles.details}>
                          <div className={styles.preview}>
                            <img src={require("../../../assets/images/homepage/profile/profile_jelly_base_icon.png")} alt="jelly" />
                          </div>
                          <div className={styles.price_container}>
                            <div className={styles.name}>JELLY</div>
                            {balanceLoading ? <Loading className={styles.loading} /> : <div className={styles.price}>{displayBalanceFloat(tokenInfoMap.get("base/jelly") || 0)}</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.chain_container}>
                      <div className={styles.chain_box}>Other Token</div>
                      <LinkButton className={styles.view_more} link="/dashboard" onClick={() => setVisible(false)}>
                        View more
                      </LinkButton>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.menu}>
                <LinkButton className={styles.item} link="/dashboard" onClick={() => setVisible(false)}>
                  <div className={styles.icon}>
                    <img src={require("../../../assets/images/homepage/profile/user_icon.png")} alt="user" />
                  </div>
                  <div className={styles.text}>My Profile</div>
                </LinkButton>
              </div>
              <div className={styles.saparator} />
              <div className={styles.menu}>
                <Link className={styles.item} onClick={() => onLogoutButtonClicked()} key="1">
                  <div className={styles.icon}>
                    <Icon name="exit" size="20" />
                  </div>
                  <div className={styles.red_text}>Disconnect</div>
                </Link>
              </div>
              <div className={styles.close_btn_container}>
                <button className={styles.close_btn} onClick={() => setVisible(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};
