import React, { useEffect, useRef } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { sleep } from "../../../../utility/helpers";
import Chart from "chart.js/auto";
import normaServerClient from "../../../../module/api/grampus/index";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { LinkButton } from "../../../elements/linkbutton";
import { isMobile, isMobileWidth } from "../../../../utility/helpers";
import { getShortenedAddress, displayBalanceFloat, numberWithCommas, getShortenedBalanceDigit } from "../../../../utility/helpers";
import { useNavigate } from "react-router-dom";
import { useIskraV2 } from "../../../../module/hook/v2/iskra";
import { TokenImage } from "../../../elements/tokeImage";
import { Loading } from "../../../elements/loading/Loading";

const menuItems = ["Dashboard"];

export const Dashboard = () => {
  const navigate = useNavigate();

  const [selectedMenu, setSelectedMenu] = React.useState(menuItems[0]);
  const { getBalanceInfo, getBalanceByName, userData, walletAddress, chainTokenList, balanceLoading } = useIskraV2();

  const [stakingInfo, setStakingInfo] = React.useState({ loading: true, lastRate: 0, stakingRate: 0, unstakingRate: 0, totalPooledGram: 0, totalPooledSgram: 0, rewardAmount: 0, apy: 0 });
  const [chartData, setChartData] = React.useState([
    { date: "2024-01-01", price: 0 },
    { date: "2024-02-01", price: 0 },
    { date: "2024-03-01", price: 0 },
    { date: "2024-04-01", price: 0 },
    { date: "2024-05-01", price: 0 },
    { date: "2024-06-01", price: 0 },
    { date: "2024-07-01", price: 0 },
    { date: "2024-08-01", price: 0 },
    { date: "2024-09-01", price: 0 },
    { date: "2024-10-01", price: 0 },
    { date: "2024-11-01", price: 0 },
    { date: "2024-12-01", price: 0 },
  ]);

  const [tokenInfo, setTokenInfo] = React.useState({
    currentPrice: 0,
    priceChange24h: {
      change: 0,
      changePercent: 0,
    },
    lowPrice24h: {
      price: 0,
      changePercent: 0,
    },
    highPrice24h: {
      price: 0,
      changePercent: 0,
    },
    volume24h: 0,
    monthlyPrices: [],
  });

  const ChartComponent = () => {
    useEffect(() => {
      const ctx = document.getElementById("myChart").getContext("2d");

      const data = {
        labels: chartData.map((item) => item.date),
        datasets: [
          {
            label: "Price",
            data: chartData.map((item) => item.price),
            fill: true,
            backgroundColor: "#445575",
            borderColor: "#4581F3",
            borderWidth: 4,
            pointRadius: 0,
          },
        ],
      };

      const options = {
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      };

      new Chart(ctx, {
        type: "line",
        data: data,
        options: options,
      });
    }, []);

    return <canvas id="myChart" className={styles.chart}></canvas>;
  };

  useEffect(() => {
    if (!walletAddress) {
      // go to landing page
      navigate("/");
      return;
    }

    updateStakingInfo();
    updateTokenInfo();
  }, [walletAddress, balanceLoading, chainTokenList]);

  async function updateStakingInfo() {
    const res = await normaServerClient.getStakingInfo();
    const data = res.data;
    const timeDiffSec = Math.floor(new Date().getTime() / 1000 - data.startInflationFromTimestamp);
    const inflationTotalAmount = timeDiffSec * data.inflationPerSec;

    setStakingInfo({
      loading: false,
      lastRate: data.lastRate.Rate,
      stakingRate: data.convertRateOriginToStaking,
      unstakingRate: data.convertRateStakingToOrigin,
      totalPooledGram: data.totalPooledOrigin,
      totalPooledSgram: data.totalSupply,
      rewardAmount: inflationTotalAmount,
      apy: data.expectedEarning * 31536000,
    });
  }

  async function updateTokenInfo() {
    try {
      const response = await normaServerClient.getTokenInfo();
      if (response.data && Object.keys(response.data).length > 0) {
        setTokenInfo(response.data);

        // Update chart data with monthly prices
        if (response.data.monthlyPrices && response.data.monthlyPrices.length > 0) {
          setChartData(
            response.data.monthlyPrices.map((item) => ({
              date: new Date(item.timestamp).toISOString().slice(0, 10),
              price: item.closePrice,
            }))
          );
        }
      } else {
        console.warn("Token info data is empty");
      }
    } catch (error) {
      console.error("Failed to fetch token info:", error);
    }
  }

  return (
    <section className={styles.section}>
      <div className={styles.background}>
        <img className={styles.pc_img} src={require("../../../../assets/images/homepage/staking_bg.png")} alt="bg" />
        <img className={cn(styles.mobile_img, styles.mobile_img_1)} src={require("../../../../assets/images/homepage/exchange_mobile_bg1.png")} alt="bg" />
        <img className={cn(styles.mobile_img, styles.mobile_img_2)} src={require("../../../../assets/images/homepage/exchange_mobile_bg3.png")} alt="bg" />
        <img className={cn(styles.mobile_img, styles.mobile_img_3)} src={require("../../../../assets/images/homepage/staking_mobile_bg1.png")} alt="bg" />
      </div>
      <div className={styles.container}>
        <div className={styles.menu_panel}>
          <div className={styles.logo_box}>
            <img src={require("../../../../assets/images/homepage/dashboard/gram_dashboard_logo.png")} alt="icon" />
          </div>
          <div className={styles.menu_box}>
            {menuItems.map((item, index) => {
              return (
                <div key={index} className={cn(styles.menu_item, { [styles.selected]: selectedMenu == item })} onClick={() => setSelectedMenu(item)}>
                  {item}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.detail_panel}>
          <div className={cn(styles.info_box, { [styles.hidden]: selectedMenu != menuItems[0] })}>
            <div className={styles.left_container}>
              <div className={styles.upper_container}>
                <div className={styles.welcome_box}>
                  <div className={styles.welcome_text}>Welcome to Gram Voyage</div>
                  <div className={styles.welcome_my_info}>
                    <div className={styles.my_info_text}>{userData?.email || ""}</div>
                    <div className={styles.my_info_text}>{walletAddress || ""}</div>
                  </div>
                </div>
              </div>
              <div className={styles.lower_container}>
                <div className={styles.card_container}>
                  <div className={styles.info_card}>
                    <div className={styles.upper_box}>
                      <div className={styles.title_text}>My Staking</div>
                      <LinkButton className={styles.my_staking_button} link="/staking">
                        Detail &gt;
                      </LinkButton>
                    </div>
                    <div className={styles.lower_box}>
                      <div className={styles.my_staking_balance_box}>
                        <div className={styles.my_staking_balance_icon_box}>
                          <img src={require("../../../../assets/images/homepage/dashboard/gram_base_icon.png")} alt="icon" />
                        </div>
                        {balanceLoading ? (
                          <div className={styles.loading_box}>
                            <Loading className={styles.loading} />
                          </div>
                        ) : (
                          <>
                            <div className={styles.my_staking_balance_value}>{displayBalanceFloat(getBalanceByName("base", "gram"))}</div>
                            <div className={styles.my_staking_balance_text}>GRAM</div>
                          </>
                        )}
                      </div>
                      <div className={styles.my_staking_balance_box}>
                        <div className={styles.my_staking_balance_icon_box}>
                          <img src={require("../../../../assets/images/homepage/dashboard/sgram_base_icon.png")} alt="icon" />
                        </div>
                        {balanceLoading ? (
                          <div className={styles.loading_box}>
                            <Loading className={styles.loading} />
                          </div>
                        ) : (
                          <>
                            <div className={styles.my_staking_balance_value}>{displayBalanceFloat(getBalanceByName("base", "staking"))}</div>
                            <div className={styles.my_staking_balance_text}>sGRAM</div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.info_card}>
                    <div className={styles.upper_box}>
                      <div className={styles.title_text}>GRAM Staking info</div>
                    </div>
                    <div className={styles.lower_box}>
                      {stakingInfo.loading ? (
                        <div className={styles.card_loading_box}>
                          <Loading className={styles.card_loading} />
                        </div>
                      ) : (
                        <>
                          <div className={styles.apy_box}>
                            <div className={styles.title_text}>APY</div>
                            <div className={styles.apy_value_box}>{displayBalanceFloat((stakingInfo?.apy || 0) * 100, 2)}%</div>
                          </div>
                          <div className={styles.title_text}>GRAM Rewards Paid</div>
                          <div className={styles.contents_text}>{displayBalanceFloat(stakingInfo?.rewardAmount || 0)} GRAM</div>
                          <div className={styles.title_text}>sGRAM Value</div>
                          <div className={styles.contents_text}>{displayBalanceFloat(stakingInfo?.unstakingRate || 0)} GRAM</div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.card_container}>
                  <div className={styles.info_card}>
                    <div className={styles.upper_box}>
                      <div className={styles.title_text}>Annual GRAM Price</div>
                    </div>
                    <div className={styles.lower_box}>
                      <div className={styles.chart_box}>
                        <ChartComponent />
                      </div>
                    </div>
                  </div>
                  <div className={styles.info_card}>
                    <div className={styles.upper_box}>
                      <div className={styles.title_text}>GRAM Price</div>
                    </div>
                    <div className={styles.lower_box}>
                      <div className={styles.price_card_box}>
                        <div className={styles.price_card}>
                          <div className={styles.price_title}>
                            <img src={require("../../../../assets/images/homepage/dashboard/up_arrow_icon.png")} alt="icon" /> 24h high
                          </div>
                          <div className={styles.price_text}>
                            ${numberWithCommas(tokenInfo.highPrice24h.price.toFixed(4))}
                            <span className={styles.percent_text}>
                              {tokenInfo.highPrice24h.changePercent > 0 ? " +" : " "}
                              {tokenInfo.highPrice24h.changePercent.toFixed(2)}%
                            </span>
                          </div>
                        </div>
                        <div className={styles.price_card}>
                          <div className={styles.price_title}>
                            <img src={require("../../../../assets/images/homepage/dashboard/down_arrow_icon.png")} alt="icon" /> 24h low
                          </div>
                          <div className={styles.price_text}>
                            ${numberWithCommas(tokenInfo.lowPrice24h.price.toFixed(4))}
                            <span className={styles.percent_text}>
                              {tokenInfo.lowPrice24h.changePercent > 0 ? " +" : " "}
                              {tokenInfo.lowPrice24h.changePercent.toFixed(2)}%
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.price_card_box}>
                        <div className={styles.price_card}>
                          <div className={styles.price_title}>
                            <img src={require("../../../../assets/images/homepage/dashboard/clock_icon.png")} alt="icon" /> 24h change
                          </div>
                          <div className={styles.price_text}>
                            ${numberWithCommas(tokenInfo.currentPrice.toFixed(4))}
                            <span className={styles.percent_text}>
                              {tokenInfo.priceChange24h.changePercent > 0 ? " +" : " "}
                              {tokenInfo.priceChange24h.changePercent.toFixed(2)}%
                            </span>
                          </div>
                        </div>
                        <div className={styles.price_card}>
                          <div className={styles.price_title}>
                            <img src={require("../../../../assets/images/homepage/dashboard/chart_icon.png")} alt="icon" /> 24h volume
                          </div>
                          <div className={styles.price_text}>${numberWithCommas(tokenInfo.volume24h.toFixed(2))}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right_container}>
              <div className={styles.welcome_box_mobile}>
                <div className={styles.welcome_text}>Welcome to Gram Voyage</div>
                <div className={styles.welcome_my_info}>
                  <div className={styles.my_info_text}>{userData?.email || ""}</div>
                  <div className={styles.my_info_text}>{walletAddress || ""}</div>
                </div>
              </div>
              <div className={styles.profile_box}>
                <div className={styles.address_container}>
                  <div className={styles.address_box}>{getShortenedAddress(walletAddress)}</div>
                </div>
                <div className={styles.avatar_box}>
                  <img src={require("../../../../assets/images/homepage/dashboard/default_avatar.png")} alt="icon" />
                </div>
                <div className={styles.balance_container}>
                  {chainTokenList.map((chainInfo, index) => {
                    return (
                      <div key={index}>
                        <div className={styles.title_box}>{chainInfo.chainName} Network</div>
                        <div className={styles.balance_box}>
                          <TokenImage className={styles.token_icon} chainId={chainInfo.chainId} />
                          {balanceLoading ? (
                            <div className={styles.loading_box}>
                              <Loading className={styles.loading} />
                            </div>
                          ) : (
                            <>
                              <div className={styles.balance_amount}>{displayBalanceFloat(getBalanceInfo(chainInfo.chainId, "0x0")?.balanceInEther || 0)}</div>
                              <div className={styles.balance_text}>{getBalanceInfo(chainInfo.chainId, "0x0")?.symbol || ""}</div>
                            </>
                          )}
                        </div>
                        {chainInfo.contractList.map((contractInfo, index) => {
                          return (
                            <div key={index}>
                              <div className={styles.balance_box}>
                                <TokenImage className={styles.token_icon} symbol={contractInfo.Category} chainId={chainInfo.chainId} />
                                {balanceLoading ? (
                                  <div className={styles.loading_box}>
                                    <Loading className={styles.loading} />
                                  </div>
                                ) : (
                                  <>
                                    <div className={styles.balance_amount}>{displayBalanceFloat(getBalanceInfo(contractInfo.ChainId, contractInfo.Address)?.balanceInEther || 0)}</div>
                                    <div className={styles.balance_text}>{getBalanceInfo(contractInfo.ChainId, contractInfo.Address)?.symbol || ""}</div>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
